<template>
  <div>
    <van-nav-bar title="任务列表" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
    <section class="van-doc-demo-section">
      <div class="van-coupon van-coupon--disabled" v-for="(item,index) in listData" :key="index">
        <div class="van-coupon__content">
          <van-row>
            <van-col span="18">
              <h2 class="van-coupon__amount">{{item.LearningProjectName}}</h2>
              <p class="van-coupon__condition">发起时间：{{item.LaunchTime}}</p>
              <p class="van-coupon__condition">提交人数：{{item.People}}</p>
            </van-col>
            <van-col span="6" v-if="item.UnCorrected !=0">
              <p class="correct_warning">未批改:{{item.UnCorrected}}</p>
            </van-col>
            <van-col span="6" v-else>
              <p class="correct_success">全部批改</p>
            </van-col>
          </van-row>
        </div>
        <div class="van-coupon__description">
          <button class="van-button  van-button-first" @click="btn_detail(item)">
            <div class="van-button_content"><span class="van-button__text">作业信息</span></div>
          </button>
          <button class="van-button  van-button-last" @click="WorkList(item)">
            <div class="van-button_content"><span class="van-button__text">作业列表</span></div>
          </button>
        </div>
      </div>
      <!--<div class="van-coupon van-coupon--disabled">
                <div class="van-coupon__content">
                    <van-row>
                        <van-col span="18">
                            <h2 class="van-coupon__amount">第三周视频作业</h2>
                            <p class="van-coupon__condition">发起时间：2017/03/10 12:00</p>
                            <p class="van-coupon__condition">提交人数：12/45</p>
                        </van-col>
                        <van-col span="6">
                            <p class="messg_success">全部批改</p>
                        </van-col>
                    </van-row>

                </div>
                <div class="van-coupon__description">
                    <button class="van-button  van-button-first" @click="btn_detail(1)"><div class="van-button_content"><span class="van-button__text">作业信息</span></div></button>
                    <button class="van-button  van-button-last" @click="WorkList(2)"><div class="van-button_content"><span class="van-button__text">作业列表</span></div></button>
                </div>
            </div>-->
    </section>
    <van-dialog v-model="contentshow" show="true" title="作业信息">
      <div style="padding:10px">
        <van-row>
          <van-col span="8">任务名称：</van-col>
          <van-col span="16">{{LearningProjectName}}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">学习项目：</van-col>
          <van-col span="16">{{LearningProjectName}}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">评价表：</van-col>
          <van-col span="16">{{EvaluateName}}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">作业要求：</van-col>
          <van-col span="16">{{Requirement}}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">发起时间：</van-col>
          <van-col span="16">{{LaunchTime}}</van-col>
        </van-row>
      </div>

    </van-dialog>
  </div>

</template>

<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      teacherID: "", // 老师ID
      LearningProjectName: "",
      EvaluateName: "",
      LaunchTime: "",
      Requirement: "",
      Title: "",
      listData: [],
      contentshow: false,

    }
  },
  methods: {
    // 获取数据
    initData() {
      this.$axios.get('/api/JobTask/TeacherJobTaskList?teacherID=' + this.teacherID, "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data)
        if (data.code == 200) {
          this.listData = data.data;
        }
      });
    },
    // 点击左上角返回
    onClickLeft() {
      this.$router.push({ name: "teacherhome" });
    },
    onClickRight() { },//这个方法不要删，上面导航返回控件必须带的
    btn_detail(item) {
      this.LearningProjectName = item.LearningProjectName;
      this.EvaluateName = item.EvaluateName;
      this.Requirement = item.Requirement;
      this.LaunchTime = item.LaunchTime;
      this.Title = item.Title;

      this.contentshow = true;

    },
    //跳转进作业批改列表
    WorkList(item) {
      this.cookies.kkSet("jobTaskID", item.ID);
      this.$router.push({ name: "teacherworklist" });
    },

  },
  mounted() {
    this.teacherID = Cookies.get('teacherID');
    this.initData();
  },
}
</script>

<style>
.van-doc-demo-section {
  box-sizing: border-box;
  min-height: calc(100vh - 56px);
  padding-bottom: 20px;
}

.van-coupon {
  margin: 12px 12px 6px 12px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px rgb(222, 219, 219);
}

.van-coupon__content {
  padding: 10px 0 14px 15px;
}

.van-row {
  padding: 5px 0 5px 0;
}

.van-col--6 {
  line-height: 3em;
  font-weight: 100;
  padding-right: 15px;
}

.correct_warning {
  color: rgba(237, 122, 112, 1);
  font-size: 14px;
  text-align: center;
}

.correct_success {
  color: rgba(42, 204, 155, 1);
  font-size: 14px;
  text-align: center;
}

.van-coupon__amount {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.van-coupon__description {
  font-size: 16px;
  text-align: center;
  border-top: 1px dashed #ebedf0;
}

.van-coupon__condition {
  font-size: 12px;
  line-height: 18px;
  white-space: pre-wrap;
}

.van-button_content {
  color: rgb(50, 148, 255);
}

.van-button {
  width: 50%;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 44px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  background: #fff;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-appearance: none;
}

.van-button-first {
  margin: 0;
  border: 0;
}

.van-button-last {
  margin: 0;
  border: 0;
  border-left: 2px solid rgb(210, 210, 210);
}
</style>